import React from 'react';
import { FacebookProvider } from 'react-facebook';
import withApolloProvider from '~/lib/apollo/with-apollo-provider';
import ConsumerLoginPage from '~/pages/ConsumerLoginPage';

const ConsumerLogin = () => (
    <FacebookProvider appId={process.env.NEXT_PUBLIC_FB_APP_ID} version="v3.2">
        <ConsumerLoginPage />
    </FacebookProvider>
);

export default withApolloProvider(ConsumerLogin);
