/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Alert } from 'antd';
import { connect } from 'react-redux';
import { NextSeo } from 'next-seo';
import PublicLayout from '~/layouts/PublicLayout';
import { loginUser } from '~/actions';
import GuestLoginForm from '../../components/GuestLoginForm';
import styles from './ConsumerLoginPage.module.scss';

const ConsumerLoginPage = ({ onLoginUser, isAuthenticated }) => {
    const router = useRouter();
    const [showThankYou, setShowThankYou] = useState(false);

    const onLoginSuccess = (loginData) =>
        new Promise((resolve) => {
            const { accessToken, expiresIn, refreshToken, user, type } = loginData;

            onLoginUser({
                accessToken,
                expiresIn,
                refreshToken,
                user,
                type,
            });
            router.replace('/');
            resolve(true);
        });

    useEffect(() => {
        if (isAuthenticated === true) {
            router.push('/');
        }
    }, [isAuthenticated, router]);

    useEffect(() => {
        const { query } = router;
        if (query.fromVerify === 'true') {
            setShowThankYou(true);
        }
    }, [router]);

    const title = 'Login | Realty.com.au';
    const description = 'Connecting you and property, differently';
    const url = `https://www.realty.com.au/login`;

    return (
        <PublicLayout>
            <NextSeo
                title={title}
                description={description}
                canonical={url}
                openGraph={{
                    type: 'website',
                    title,
                    description,
                    locale: 'en_US',
                    url,
                }}
            />
            <div className="full-page flex items-center justify-center py-4">
                <div className="mx-6 w-[548px]">
                    {showThankYou && (
                        <Alert
                            closable
                            showIcon
                            className="mb-4 text-center text-xl"
                            message="Thank you for verifying your account!"
                            type="success"
                        />
                    )}
                    <GuestLoginForm onSuccess={onLoginSuccess} onSuccessAccount={onLoginSuccess} isPage />
                </div>
            </div>
        </PublicLayout>
    );
};

ConsumerLoginPage.propTypes = {
    onLoginUser: PropTypes.func,
    isAuthenticated: PropTypes.bool,
};

ConsumerLoginPage.defaultProps = {
    onLoginUser: () => {},
    isAuthenticated: false,
};

export default connect(
    (state) => ({ isAuthenticated: state.auth.isAuthenticated && state.auth.type === 'CONSUMER' }),
    (dispatch) => ({
        onLoginUser(accessToken) {
            dispatch(loginUser(accessToken));
        },
    })
)(ConsumerLoginPage);
